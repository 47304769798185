.container {
  font-family: var(--TARGET-FF);
  border-radius: .222rem;
  background-color: var(--TERTIARY-BGCOLOR);
  box-shadow: 0 0 8px #000;
  width: 45vw;
  max-width: 320px;
}

.available {
  opacity: 1;
  cursor: pointer;
}

.unavailable {
  opacity: 0.333;
  /* opacity: 0.444; */
  /* opacity: 0.150; */
  cursor: default;
}

.figure {
  display: flex;
  flex-direction: column;
  width: 100%
}

.lessonCoverContainer {
  position: relative;
  width: auto;
  height: auto;
  max-height: 25vh;
  display: block;
  overflow: hidden;
}

.lessonCoverOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: 100%;
  border-top-left-radius: .222rem;
  border-top-right-radius: .222rem;

  display: grid;
  place-content: center;

  color: var(--SECONDARY-COLOR);
  padding: 0.5rem;

  background-color: hsla(0, 0%, 0%, 0.50);
  backdrop-filter: blur(1px);
}

.subscribe {
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: center;
}

.subscribeWrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.subscribeIcon {
  font-size: 1.5rem;
}

.comingSoon {
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: center;
}

.comingSoonWrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.comingSoonIcon {
  font-size: 1.5rem;
}

.lessonCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
  border-top-left-radius: .222rem;
  border-top-right-radius: .222rem;
  max-height: 400px;
}

.figcaption {
  padding: 0.5rem .5rem .5rem .5rem;
  text-decoration: none;
  color: var(--SECONDARY-COLOR);
  display: flex;
  flex-direction: column;
}

.lessonHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  color: var(--SECONDARY-COLOR);
  font-style: italic;
  font-size: 1.2rem;
}

.chapterTitle {
  font-size: 1.125rem;
  font-weight: lighter;
  color: var(--SECONDARY-COLOR);
}

.bookTitle {
  font-style: italic;
}

.lessonAuthor {
  color: var(--SECONDARY-COLOR);
  font-size: .85rem;
  /* margin-top: 0.2rem; */
}

.figcaption_vocabRow {
  /* margin-top: 0.2rem; */
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  font-weight: bold;
  margin-top: .25rem;
}

.percentagesRow {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  font-weight: lighter;
  padding: 0.25rem 1rem;
  background-color: var(--SENTENCE-TARGET-BGCOLOR);
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  flex-grow: 1;
  width: 100%;
}

.figcaption_vocabFlag {
  display: block;
  height: .8rem;
  width: auto;
  opacity: .75;
  /* border-top-left-radius: 0.5rem; */
  /* border-bottom-left-radius: 0.5rem; */
}

.figcaption_vocab__new,
.figcaption_vocab__seen,
.figcaption_vocab__known {
  font-size: .8rem;
}

.figcaption_vocab__new {
  color: var(--NEW-STEM-COLOR);
}

.figcaption_vocab__seen {
  color: var(--SEEN-STEM-COLOR);
}

.figcaption_vocab__known {
  color: var(--PRIMARY-COLOR);
}

@media screen and (min-width: 500px) {

  .lessonCoverContainer {
    max-height: 45vh;
  }
}

/* * tablet, desktop */
@media screen and (min-width: 650px) {
  /* .figure {
    max-width: 308.383px;
  } */

  .lessonHeader {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    color: var(--SECONDARY-COLOR);
    font-style: italic;
    font-size: 1.75rem;
  }

  .chapterTitle {
    font-size: 1.5rem;
    font-weight: lighter;
    color: var(--SECONDARY-COLOR);
  }

  .lessonAuthor {
    color: var(--SECONDARY-COLOR);
    font-size: 1.65rem;
    /* margin-top: 0.2rem; */
  }

  .figcaption {
    text-decoration: none;
    color: var(--PRIMARY-COLOR);
    font-size: 0.85rem;
    display: flex;
    flex-direction: column;
    gap: .25rem;
    padding: 0.5rem 1rem 1rem 1rem;
  }

  .figcaption_vocabFlag {
    display: block;
    height: 1.25rem;
    width: auto;
    opacity: .75;
    /* border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem; */
  }

  .figcaption_vocab__new,
  .figcaption_vocab__seen,
  .figcaption_vocab__known {
    font-size: 1.25rem;
  }

  .comingSoon {
    font-size: 2.5rem;
    font-weight: bolder;
    text-align: center;
  }

  .comingSoonIcon {
    font-size: 3rem;
  }

  .subscribe {
    font-size: 2.5rem;
    font-weight: bolder;
    text-align: center;
  }

  .subscribeIcon {
    font-size: 3rem;
  }
}