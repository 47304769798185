.gridInfo {
  display: grid;
  grid-template-areas:
    "info_header info_header info_header"
    "info_content info_content info_content";
  grid-template-rows: auto 1fr;
  grid-area: info;
  min-height: 25vh;
}

.gridInfo_content {
  grid-area: info_content;
  display: grid;
  width: 100%;
  grid-template-areas: "translation grammar"
    "translation grammar";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  overflow-y: auto;
  margin-bottom: 3.71875rem;

  margin-top: var(--SENTENCE-CONTENT-MARGIN);
  margin-bottom:
    0;
  scroll-padding-bottom: 3.71875rem;
}

.translationCol {
  grid-area: translation;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.500rem;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: .5px solid var(--BORDER-COLOR);
  padding-right: 9px;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  /* padding-bottom: 4px; */
  font-weight: 900;
  background-color: var(--SECONDARY-BGCOLOR);
  z-index: 1;
  height: 1rem;
}

.translationTitle {
  font-family: var(--TARGET-FF);
  color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR)
}

.grammarTitle {
  color: var(--SECONDARY-COLOR);
}

.header__off {
  color: var(--SECONDARY-COLOR);
}

.headerToggle__sentence,
.headerToggle__grammar {
  cursor: pointer;
  color: var(--SECONDARY-COLOR);
  /* height: 40px; */
  width: 40px;
}

.headerToggle__grammar {
  position: relative;
  /* left: 0px;
  top: -10.5px; */
  font-size: .7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* margin-bottom: 2px; */
  height: 1rem
}

.headerToggle__sentence {
  position: relative;
  /* right: 1px; */
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
}

.sentenceButtonOn,
.sentenceButtonOff {
  position: relative;
  border-radius: .222 rem;
}

.sentenceButtonOn {
  color: var(--SECONDARY-BGCOLOR);
  transition: color 500ms ease-out;
}

.sentenceButtonOff {
  color: var(--SECONDARY-COLOR);
  transition: color 500ms ease-out 500ms;
}

.languageOnBackground,
.languageOffBackground {
  height: 10px;
  width: 22px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  /* top: 25%;
  left: 5%; */
}

.languageOnBackground {
  background-color: var(--SECONDARY-COLOR);
  transition: background-color 500ms ease-out 500ms;
}

.languageOffBackground {
  background-color: var(--SECONDARY-BGCOLOR);
  transition: background-color 500ms ease-out;
}

.translationContent {
  flex-grow: 1;
  width: 100%;
  min-height: 0;
  overflow-y: auto;
  font-family: var(--FF);
  /* font-family: var(--TARGET-FF); */
  color: var(--SECONDARY-COLOR);
  border-top-left-radius: 0.222rem;
  border-top-right-radius: 0.222rem;
  -webkit-mask-image: linear-gradient(to bottom,
      black 80%,
      transparent 100%);
  mask-image: linear-gradient(to bottom,
      black 80%,
      transparent 100%);
  padding-bottom: 1rem;
}

.translationContent::-webkit-scrollbar {
  display: none;
}

.sentenceTranslation {
  font-size: 1rem;
  line-height: 150%;
  text-align: left;
  padding-bottom: 8px;
  text-align: justify;
  /* opacity: 0; */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* justify-content: space-between; */
}

.sentenceTranslation.revealed {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.sentenceTranslation.hidden {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

@media screen and (min-width: 500px) {
  .sentenceTranslation {
    text-align: justify;
  }
}

.grammarCol {
  grid-area: grammar;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* overflow-y: auto;
  overflow-x: hidden; */
  gap: 0.500rem;
  padding-left: 9px;
}

.grammarContent {
  flex-grow: 1;
  width: 100%;
  overflow: visible;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* gap: 0.500rem; */
  padding-top: 5px;
  overflow-anchor: none;
  border-top-left-radius: 0.222rem;
  border-top-right-radius: 0.222rem;
  -webkit-mask-image: linear-gradient(to bottom,
      black 80%,
      transparent 100%);
  mask-image: linear-gradient(to bottom,
      black 80%,
      transparent 100%);
  padding-bottom: 1rem;
}

.grammarContent::-webkit-scrollbar {
  display: none;
}

.grammarItem {
  color: var(--SECONDARY-COLOR);
}

.grammarItem__selected {
  color: var(--PRIMARY-COLOR);
  text-decoration: underline;
  text-decoration-color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
}

.grammarOnBackground,
.grammarOffBackground {
  background-color: var(--SECONDARY-COLOR);
  height: 14.5px;
  width: 24px;
  position: relative;
  /* top: -13%;
  right: -45%; */
  border-radius: .111rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grammarOnBackground {
  background-color: var(--SECONDARY-COLOR);
  transition: background-color 500ms ease-out 500ms;
}

.grammarOffBackground {
  background-color: var(--SECONDARY-BGCOLOR);
  transition: background-color 500ms ease-out;
}

.colorUp {
  animation: colorUp 500ms ease-out;
}

@keyframes colorUp {
  0% {
    color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  }

  50% {
    color: var(--NEW-STEM-COLOR);
  }

  100% {
    color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  }
}

.colorDown {
  animation: colorDown 500ms ease-out forwards;
}

@keyframes colorDown {
  0% {
    color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  }

  50% {
    color: var(--NEW-END1-COLOR);
  }

  100% {
    color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  }
}

.fadeInWord {
  opacity: 0;
  transform: translateY(10px);
  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-out;
  margin-right: .2rem;
  margin-left: .2rem;
}

.sentenceTranslation.revealed .fadeInWord {
  opacity: 1;
  transform: translateY(0);
}

.spacer {
  flex-grow: 1;
  min-width: 0px;
  height: 0px;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(4px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(-4px);
  }

  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.2s infinite;
}