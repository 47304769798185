.logo {
  grid-area: header0;
  display: inline;
  width: fit-content;
  padding-bottom: 2px;
  cursor: pointer;
  filter: saturate(90%);
  opacity: .75;
  transition: opacity 500ms ease;
}

.logo:hover {
  opacity: 1;
}

.locationContainer {
  grid-area: header1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 2px;
}

.locationText {
  font: 0.75rem var(--TARGET-FF);
  text-align: center;
  color: var(--BORDER-COLOR);
  min-width: 150px;
}

.bookTitle {
  font-style: italic;
  font-size: 1.125rem;
}

.chapterTitle {
  font-size: 0.8775rem;
}

.sentencePosition {
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.text {
  margin-right: .15rem
}

.slash {
  margin-left: .15rem
}

/* Ensure FlipDigit aligns properly */
.flipDigitContainer {
  margin: 0 1px;
}

.header2__columnContainer {
  grid-area: header2;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 2px;
}

.header2__iconRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}

.knownCountContainer {
  display: flex;
  flex-direction: row;
  gap: 0.400rem;
  align-items: center;
  justify-content: flex-end;
  bottom: 0px;
  right: 0px;
  text-align: right;
}

.flag {
  display: block;
  height: auto;
  width: 1rem;
}

.knownCount {
  font-family: var(--TARGET-FF);
  font-weight: 550;
  font-size: 1.2rem;
}

.headerExit {
  color: var(--SECONDARY-COLOR);
  text-align: center;
}

.iconRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.icon {
  font-size: 1.2rem;
  padding: 1rem;
  border-radius: 0.222rem;
  color: var(--BORDER-COLOR);
  cursor: pointer;
  transition: color 500ms ease;
}

.icon__info {
  font-size: 1rem;
  padding: 0 0 1rem 1rem;
  border-radius: 0.222rem;
  color: var(--BORDER-COLOR);
  cursor: pointer;
  transition: color 500ms ease;
}

.icon__disabled {
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.222rem;
  color: transparent;
  cursor: default;
  transition: color 500ms ease;
}

@media (pointer: fine) {
  .icon:hover {
    color: var(--SECONDARY-COLOR);
  }
}

.icon__admin {
  font-size: 1rem;
  background-color: orange;
  color: #000;
  padding: 4px;
  border-radius: 0.222rem;
  cursor: pointer;
}