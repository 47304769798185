@keyframes slideInFromRight {
    from {
        transform: translateX(50%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(-50%);
        opacity: 0;
    }
}

.slideIn {
    animation-name: slideInFromRight;
    animation-fill-mode: forwards;
}

.slideOut {
    animation-name: slideOutToLeft;
    animation-fill-mode: forwards;
}

/* --- New Reverse Animations --- */
@keyframes slideInFromLeft {
    from {
        transform: translateX(-50%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutToRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(50%);
        opacity: 0;
    }
}

.slideInReverse {
    animation-name: slideInFromLeft;
    animation-fill-mode: forwards;
}

.slideOutReverse {
    animation-name: slideOutToRight;
    animation-fill-mode: forwards;
}

/* ... existing styles ... */
.container {
    /* position: absolute; */
    display: flex;
    height: 7rem;
    justify-content: center;
    align-items: flex-start;
    color: var(--SECONDARY-COLOR);
    /* border-top: 0.5px solid var(--BORDER-COLOR); */
    width: calc(100vw - (2 * 0.555rem));
    font-size: 1.5rem;
    /* top: 57.5%; */
    /* margin-left: 0.555rem; */
    text-align: center;
    transition: height 500ms ease-out;
    grid-area: guide;
    overflow: hidden;
}

.hideGuide {
    height: 0;
}

.hide {
    position: absolute;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--TERTIARY-BGCOLOR);
    font-family: var(--TARGET-FF);
    font-weight: 900;
    height: 40px;
    width: 40px;
    cursor: pointer;
    z-index: 2;
    transition: transform 500ms ease-out;
}

.hideText {
    position: absolute;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--TERTIARY-BGCOLOR);
    font-family: var(--TARGET-FF);
    font-weight: 900;
    height: 40px;
    width: 120px;
    cursor: pointer;
    z-index: 2;
    transition: opacity 500ms ease-out;
    transform: translateY(-30px);
}

.guideAlign {
    height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: opacity 250ms ease-out;
    width: 100%;
    background-color: var(--PRIMARY-BGCOLOR);
}

.guideAlignHide {
    position: relative;
    /* opacity: 0; */
    /* height: 0 */
}

.check {
    font-size: 24px;
    width: 28px;
    color: var(--NEW-STEM-COLOR);
    mask: linear-gradient(90deg,
            rgba(255, 255, 255, 0.7) 0%,
            rgba(255, 255, 255, 0.7) 40%,
            rgba(255, 255, 255, 0.7) 50%,
            rgba(255, 255, 255, 0.7) 60%,
            rgba(255, 255, 255, 0.7) 100%);
    mask-size: 200% 100%;
    mask-type: alpha;
    cursor: pointer;
    padding: .5rem
}

.x {
    font-size: 18px;
    width: 28px;
    color: red;
    mask: linear-gradient(90deg,
            rgba(255, 255, 255, 0.7) 0%,
            rgba(255, 255, 255, 0.7) 40%,
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 0.7) 60%,
            rgba(255, 255, 255, 0.7) 100%);
    mask-size: 200% 100%;
    mask-type: alpha;
    transition: color 1s ease, mask-position 1s ease-out;
    cursor: pointer;
    padding: .5rem
}

.downArrow {
    font-size: 28px;
    width: 28px;
    margin-top: 1rem;
}

.language {
    font-size: 20px;
}

.downChevron {
    font-size: 1.2rem;
    color: var(--BORDER-COLOR);
}

.icon {
    font-size: 1rem;
}

.grammarOnBackground,
.grammarOffBackground {
    background-color: var(--SECONDARY-COLOR);
    height: 14.5px;
    width: 24px;
    position: absolute;
    top: -13%;
    right: -45%;
    border-radius: .111rem;
}

.grammarOnBackground {
    background-color: var(--SECONDARY-COLOR);
    transition: background-color 500ms ease-out 500ms;
}

.grammarOffBackground {
    background-color: var(--SECONDARY-BGCOLOR);
    transition: background-color 500ms ease-out;
}

.sentenceButtonOn,
.sentenceButtonOff {
    position: relative;
    border-radius: .222 rem;
}

.sentenceButtonOn {
    color: var(--SECONDARY-BGCOLOR);
    transition: color 500ms ease-out;
}

.sentenceButtonOff {
    color: var(--SECONDARY-COLOR);
    transition: color 500ms ease-out 500ms;
}

.headerToggle__sentence,
.headerToggle__grammar {
    cursor: pointer;
    color: var(--SECONDARY-COLOR);
    height: 30px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerToggle__grammar {
    position: relative;
    font-size: .7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 5px; */
}

.headerToggle__sentence {
    position: absolute;
    right: 1px;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}