.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 0.333rem; */
  width: 100%;
  cursor: pointer;
  height: auto
}

.itemRow.selected {
  border: 1px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
}

.container:last-child {
  padding-bottom: 8px;
}

.itemRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* gap: 0.667rem; */
  padding: 0.222rem;

  border-radius: 0.222rem;
  /* background-color: var(--TERTIARY-BGCOLOR); */
  /* background-color: var(--SENTENCE-TARGET-BGCOLOR); */
  background-color: #2b2b2b;
  /* border: 1px solid var(--PRIMARY-BGCOLOR); */
  border: 1px solid transparent;
  transition: border 500ms ease-out;
}

.itemRow__selected {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* gap: 0.667rem; */
  padding: 0.222rem;

  border-radius: 0.222rem;
  /* background-color: var(--TERTIARY-BGCOLOR); */
  /* background-color: var(--SENTENCE-TARGET-BGCOLOR); */
  background-color: #2b2b2b;
  border: 1px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  transition: border 500ms ease-out;
}


.icon {
  padding-left: 0.125rem;
  color: var(--SECONDARY-COLOR);
  transition: transform 500ms ease-out;
  transform: rotate(-90deg);
}

.iconOpen {
  transform: rotate(0deg);
}

.infoIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* gap: 0.111rem; */
  width: 16px;
  height: 16px;
  color: var(--SECONDARY-COLOR);
}

.item {
  color: var(--SECONDARY-COLOR);
  text-align: left;
  font-size: 1rem;
}

.item__selected {
  color: var(--PRIMARY-COLOR);
  text-decoration: underline;
  text-decoration-color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
}

.descriptionContainer {
  border-radius: 0.222rem;
  /* background-color: var(--TERTIARY-BGCOLOR); */
  /* background-color: var(--SENTENCE-TARGET-BGCOLOR); */
  background-color: #2b2b2b;
  /* border: 1px solid var(--PRIMARY-BGCOLOR); */
  border: 1px solid transparent;
  padding: 0.222rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 0.444rem; */
  transition: border 500ms ease-out;
}

.descriptionContainer__selected {
  border-radius: 0.222rem;
  /* background-color: var(--TERTIARY-BGCOLOR); */
  /* background-color: var(--SENTENCE-TARGET-BGCOLOR); */
  background-color: #2b2b2b;
  border: 1px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  padding: 0.222rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 0.444rem; */
  transition: border 500ms ease-out;
}

.slide {
  animation: slide 4000ms ease-out;
}

@keyframes slide {
  0% {
    transform: translateX(0px);
  }

  6.75% {
    transform: translateX(10px);
  }

  12.5% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(0px);
  }
}

.valueSpan {
  font-style: italic;
}

.itemInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--SECONDARY-COLOR);
}

.attributeName {
  /* color: var(--SECONDARY-COLOR); */
  font-weight: 900;
  padding-bottom: 0.222rem;
  font-size: 1rem;
  transition: color 500ms ease-out;
}

.attributeValue {
  /* color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR); */
  transition: color 500ms ease-out;
  font-style: italic
}

.attributeNameDescription {
  color: var(--SECONDARY-COLOR);
  /* padding: 0.114rem 0.114rem 0.114rem 0.456rem; */
  /* border-radius: 0.222rem; */
  font-weight: 400;
  font-size: 1rem;
  transition: color 500ms ease-out;
  padding-top: 3px;
  margin-top: 3px;
  border-top: solid .5px var(--BORDER-COLOR);
  text-align: left;
  /* padding-top: 8px; */
  /* margin-left: 5%;
  margin-right: 5%; */
}

.valueDescription {
  /* color: var(--TERTIARY-BGCOLOR); */
  /* background-color: var(--SECONDARY-COLOR); */
  /* border-radius: 0.222rem; */
  /* padding: 0.222rem; */
  font-weight: 100;
  font-size: 1rem;
  transition: color 500ms ease-out;
  font-style: italic;
  text-align: left;
  padding-top: 3px;
  /* text-indent: 5%; */
  /* margin-left: 5%;
  margin-right: 5%; */
}

.descriptions {
  overflow: hidden;
  transition: max-height 500ms ease-in-out;
}

.descriptions__show {
  width: 100%
}

.descriptions__hide {
  width: 100%
}

@media screen and (min-width: 1024px) {}